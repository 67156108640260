<template>
<div>
    <v-container>
        <h1 class="mb-3">料金の設定</h1>
        <p>設定を変更後「保存する」をクリックしてください</p>
        <v-btn color="#E64A19" x-large dark class="f-12 my-6" @click="submit()">保存する</v-btn>
        <v-form ref="form">
            <v-card class="px-6 pt-6 pb-4 price_box relative-center mb-6">
                <div class="bold f-12 f-gray mb-2">お部屋の施工料金 (税込)</div>
                <div class="bold f-brown text-left mb-2">床面積１平方メートルあたりの単価</div>
                <v-text-field v-model="unitPrice" :rules="priceRules" label="床面積１平方メートルあたりの単価" outlined type="number" max="1000000" min="0" />
                <div class="text-left">
                    【 例 】<br>
                    １．シングルルーム&nbsp;12m<sup>2</sup><br>
                    &nbsp;<span class="f-08">床面積</span>&nbsp;12m<sup>2</sup>&nbsp;×&nbsp;<span class="f-08">単価</span>&nbsp;{{unitPrice.toLocaleString()}}&nbsp;=
                    <div class="text-right relative t--5">
                        <span class="bold f-orange f-14">{{(Math.ceil(12*unitPrice / 100) * 100).toLocaleString()}}<span class="f-08">&nbsp;円</span></span> <br>
                    </div>
                    ２．和室&nbsp;10畳<br>
                    &nbsp;<span class="f-08">床面積</span>&nbsp;10畳&nbsp;×&nbsp;<span class="f-08">係数</span>&nbsp;{{Tatami}}&nbsp;×&nbsp;<span class="f-08">単価</span>&nbsp;{{unitPrice.toLocaleString()}}&nbsp;=
                    <div class="text-right relative t--5">
                        <span class="bold f-orange f-14">{{(Math.ceil(10*Tatami*unitPrice / 100) * 100).toLocaleString()}}<span class="f-08">&nbsp;円</span></span><br>
                    </div>
                    <div class="f-08 mb-1">※料金は十の位で切り上げ</div>
                    <div class="f-08 mb-1">※1畳あたり{{Tatami}}m<sup>2</sup>にて計算</div>
                </div>
            </v-card>

            <v-card class="px-6 pt-6 pb-4 price_box relative-center mb-6">
                <div class="bold f-12 f-gray mb-2">自動車の施工料金 (税込)</div>
                <div class="bold f-brown text-left mb-2">{{types[0].name}}１台あたりの料金</div>
                <v-text-field v-model="kei" :rules="priceRules" :label="types[0].name+'１台あたりの料金'" outlined type="number" max="1000000" min="0" />
                <div class="bold f-brown text-left mb-2">{{types[1].name}}１台あたりの料金</div>
                <v-text-field v-model="normal" :rules="priceRules" :label="types[1].name+'１台あたりの料金'" outlined type="number" max="1000000" min="0" />
                <div class="bold f-brown text-left mb-2">{{types[2].name}}１台あたりの料金</div>
                <v-text-field v-model="onebox" :rules="priceRules" :label="types[2].name+'１台あたりの料金'" outlined type="number" max="1000000" min="0" />
            </v-card>

            <v-card class="px-6 pt-6 pb-4 price_box relative-center mb-6">
                <div class="bold f-12 f-gray mb-2">１注文あたりの最低金額</div>
                <v-text-field v-model="minTotal" :rules="priceRules" label="１注文あたりの最低金額" outlined type="number" max="10000000" min="0" />
            </v-card>

             <v-card class="px-6 pt-6 pb-4 price_box relative-center mb-6">
                <div class="bold f-12 f-gray mb-2">キャンペーンの設定</div>
                <p>「トイレ１室&nbsp;または&nbsp;お車１台&nbsp;<br class="dis-xxs">無料キャンペーン」の有無を設定します</p>
                 <v-checkbox label="キャンペーン有り" color="indigo" hide-details v-model="campaign" ></v-checkbox>
            </v-card>
        </v-form>
        <v-btn color="#E64A19" x-large dark class="f-12 my-6" @click="submit()">保存する</v-btn>
    </v-container>
    <Snackbar ref="snack" />
</div>
</template>

<script>
import form from '@/mixins/form'
import Snackbar from "@/components/snackbar.vue"
import axios from "axios"
import d from "@/d"

export default {
    mixins: [form],
    data() {
        return {
            priceRules: [(v) => v > 0 || "0以上の数字です"],
        }
    },
    components: {
        Snackbar,
    },
    created() {
        this.unitPrice = this.Info.unitPrice
        this.kei = this.Info.kei
        this.normal = this.Info.normal
        this.onebox = this.Info.onebox
        this.minTotal = this.Info.minTotal
        this.campaign=this.Info.campaign
    },
    methods: {
        async submit() {

            if (!this.$refs.form.validate()) {
                return false;
            }
            const data = {
                unitPrice: this.unitPrice,
                kei: this.kei,
                normal: this.normal,
                onebox: this.onebox,
                minTotal: this.minTotal,
                campaign:this.campaign
            }

            const response = await axios.post("/user/priceSave", data)
            if (response.data.ok) {
                await this.$store.dispatch("user/save", response.data);
                this.$refs.snack.snack = true
                this.$refs.snack.message = "保存しました"
            } else {
                alert("エラーが発生しました")
            }
        }
    },
    watch: {
        async unitPrice(val) {
            await d.sleep(10)
            if (val < 0) {
                this.unitPrice = 0
            }
        },
        async kei(val) {
            await d.sleep(10)
            if (val < 0) {
                this.kei = 0
            }
        },
        async normal(val) {
            await d.sleep(10)
            if (val < 0) {
                this.normal = 0
            }
        },
        async onebox(val) {
            await d.sleep(10)
            if (val < 0) {
                this.onebox = 0
            }
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/user/price.scss";
</style>
